/* eslint-disable no-unused-vars */
const PORT = process.env['PORT'] || 8080
const BASE_URL = 'https://rxauto.be'
const BASE_URL_WEB = 'https://navio.cm'
const BASE_URL_TEST_API = 'https://navio.cm/api'
const BASE_URL_API = 'https://rxauto.be/api'
const PAGESIZE = 25
const GOOGLE_API_KEY = 'AIzaSyBwEXwc8-O0xbFzsD2GM5hxheryfIObu9w'
const EXPO_PROJECT_ID = '40fa23f0-d2d3-449d-8eac-697803021c79'
const GOOGLE_AUTH_WEBCLIENT_ID =
  '73292741332-1idtmlhgcvrb8ndl0tsskpgnfi6lvjie.apps.googleusercontent.com'
const GOOGLE_AUTH_ANDROID_ID =
  '73292741332-89rn1eerbcevlbg2uvligag20pc7067a.apps.googleusercontent.com'
const GOOGLE_AUTH_IOS_ID = '73292741332-0p8focd492hbf1nhogq66hsntka8c8ld.apps.googleusercontent.com'
//const GOOGLE_AUTH_IOS_ID ='73292741332-4c4m404gp48v1ltssre0r40dijdv518f.apps.googleusercontent.com';
const STRIPE_PUBLIC_KEY =
  'pk_live_51MssnQJyK4P604VICxeD57TP2Ur2HTOlkzypmW25KRtVbY5nS01xejvYdbPW2FwYT1Ov6NZH04CwxUVnq9KOmkNo006F6GlHR9'
const STRIPE_SECRET_KEY =
  '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'
const PAYPAL_CLIENT_ID =
  'AW6sOoNCHuIdzT3u_MtQuL-f7L2_il5Q8xyNZl2LzrKRzrnLeIvdj_UjrHGbkA7RktgCmLyMud_oiSal'
const PAYPAL_SECRET =
  'EM9VU5h6u8PgyiZ45M8PZHfyNc_wKNi61ITmeA8XSx1iI4rEq8cTy3uyuibQpFRuPgE-7j1sK8-laeTr'
const PAYPAL_BASE_URL = 'https://api-m.sandbox.paypal.com'
const STRIPE_API_URL = `https://rxauto.be/api/stripe/setup`
const APPLE_MERCHANT_ID = 'com.navio.rxautoapp' //'merchant.com.navio.rxautoapp';
const APPID_ANDROID = '1:73292741332:android:f929eb7e0166dea5529941'
const APPID_IOS = '1:73292741332:android:f929eb7e0166dea5529941'
const ORANGE_MTN_MONEY_HOSTNAME_TEST = 'http://app.dev.olydis.io'
const ORANGE_MTN_MONEY_HOSTNAME = 'https://app.pprd.olydis.io'
const ORANGE_MTN_MONEY_API_USER_TEST = 'c2a1a24f-69f8-4db9-9d53-2e7a65de8a18'
const ORANGE_MTN_MONEY_API_KEY_TEST = 'bb5b2174-cc1d-4901-a514-98b8667997f6'
const ORANGE_MTN_MONEY_API_USER = '2821b8f7-d9e4-4fe3-bbc7-38ab9f8135a4'
const ORANGE_MTN_MONEY_API_KEY = '6d6e1a58-620b-4b0f-a6c2-9303ae429701'
const NOTIFICATION_EVENTTYPES = {
  dashboard: 'Home',
  review: 'DriverReview',
  trips: 'Trip',
  drivers: 'Drivers',
  clients: 'Clients',
  reservations: 'Reservation',
}
const ACCESS_TOKEN_SECRET = '@NAVIO!!Cm*APP!!secret@Access'
const REFRESH_TOKEN_SECRET = '@NAVIO!!Cm*APP!secret&Refresh'

// Your Firebase config object
const FIREBASECONFIG = {
  apiKey: 'AIzaSyBwEXwc8-O0xbFzsD2GM5hxheryfIObu9w',
  authDomain: 'rxauto.firebaseapp.com',
  projectId: 'rxauto',
  storageBucket: 'rxauto.appspot.com',
  messagingSenderId: '73292741332',
  appId: '1:73292741332:web:659e7cb41eb9aa6e529941',
  measurementId: 'G-246ETF7RVX',
  databaseURL: 'https://rxauto-default-rtdb.europe-west1.firebasedatabase.app',
}

const SOCKET_CHANNELS = {
  REVIEWS: 'RX_REVIEWS',
  COMMENTS: 'RX_COMMENTS',
  TRIPS: 'RX_TRIPS',
  ALLNEWTRIPS: 'RX_ALLNEWTRIPS',
  CLIENTS: 'RX_CLIENTS',
  DRIVERS: 'RX_DRIVERS',
  ASSIGMENTS: 'RX_ASSIGMENTS',
  USERS: 'RX_USERS',
  RESERVATIONS: 'RX_RESERVATIONS',
  ORANGEMTNMONEY: 'ORANGEMTNMONEY',
  MESSAGES: 'MESSAGES',
  SUPPORT_TICKETS: 'SUPPORT_TICKETS',
}

const MAKE_SOCKET_CHANNEL = (id, channel) => {
  if (!id) return channel
  else return `${channel}_${id}`
}
const BUILD_BROADCAST_BODY = (
  broadcastPayload,
  broadcastEvent,
  broadcastChannel,
  broadcastAction,
) => {
  return { broadcastPayload, broadcastEvent, broadcastChannel, broadcastAction }
}

module.exports = {
  BASE_URL,
  PORT,
  PAGESIZE,
  GOOGLE_API_KEY,
  EXPO_PROJECT_ID,
  FIREBASECONFIG,
  GOOGLE_AUTH_WEBCLIENT_ID,
  GOOGLE_AUTH_ANDROID_ID,
  GOOGLE_AUTH_IOS_ID,
  MAKE_SOCKET_CHANNEL,
  BUILD_BROADCAST_BODY,
  SOCKET_CHANNELS,
  STRIPE_PUBLIC_KEY,
  STRIPE_SECRET_KEY,
  PAYPAL_CLIENT_ID,
  PAYPAL_SECRET,
  PAYPAL_BASE_URL,
  STRIPE_API_URL,
  APPLE_MERCHANT_ID,
  NOTIFICATION_EVENTTYPES,
  ORANGE_MTN_MONEY_HOSTNAME,
  ORANGE_MTN_MONEY_API_USER,
  ORANGE_MTN_MONEY_API_KEY,
  ORANGE_MTN_MONEY_API_USER_TEST,
  ORANGE_MTN_MONEY_API_KEY_TEST,
  ORANGE_MTN_MONEY_HOSTNAME_TEST,
  ACCESS_TOKEN_SECRET,
  REFRESH_TOKEN_SECRET,
  BASE_URL_API,
}
